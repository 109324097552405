/* Login.module.css */
.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #0d0d0d 0%, #1e1e1e 100%);
    color: #fff;
}

.loginBox {
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 3rem;
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.logo {
    width: 120px;
    margin-bottom: 1.5rem;
}

.loginForm {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.heading {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.inputField {
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.inputField:focus {
    background-color: rgba(255, 255, 255, 0.3);
}

.loginButton {
    padding: 0.75rem;
    border-radius: 8px;
    border: none;
    background-color: #00bfa6;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.loginButton:disabled {
    background-color: #009481;
}


.loginButton:hover {
    background-color: #00a692;
}

.forgotPassword {
    color: #00bfa6;
    text-decoration: none;
    font-size: 0.9rem;
}

.forgotPassword:hover {
    text-decoration: underline;
}

.error {
    color: #ff3860;
    font-size: 0.9rem;
}
